import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      ja: {
        translation: {
          companyName: "Hacozy合同会社",
          brandName: "Hacozy",
          home: "ホーム",
          about: "会社概要",
          services: "サービス",
          contact: "お問い合わせ",
          heroTitle: "テクノロジーで教育を革新する",
          heroSubtitle: "日本語学習と習慣形成を新しい方法で。",
          getStarted: "始めましょう",
          aboutUs: "会社概要",
          aboutDescription: "Hacozy合同会社は、誰もが簡単にアクセスできる質の高いオンライン教育を提供することを目指しています。",
          ourServices: "サービス",
          yuruiNihongoTitle: "Yurui Nihongo",
          yuruiNihongoDescription: "JLPT試験対策のための日本語教育サービス。英語話者とビルマ語話者に対応しています。",
          learnMore: "詳細を見る",
          habitAppTitle: "習慣形成アプリ - Tomo",
          habitAppDescription: "近日公開！ポジティブな習慣を形成し、維持するための革新的なアプリです。",
          joinWaitlist: "ウェイトリストに参加",
          testimonials: "お客様の声",
          testimonial1: "ゆるい日本語で日本語学習が楽しくなりました。レッスンが魅力的で、先生方のサポートも素晴らしいです！",
          testimonial1Author: "サラ L.、JLPT N3合格者",
          testimonial2: "ビルマ語話者として、良質な日本語学習リソースを見つけるのは難しかったですが、ゆるい日本語に出会えて本当に良かったです。",
          testimonial2Author: "マウン M.、JLPT N4学習者",
          contactUs: "お問い合わせ",
          contactDescription: "ご質問やお問い合わせはこちらからどうぞ。",
          sendEmail: "メールを送る",
          quickLinks: "クイックリンク",
          followUs: "フォローする",
          facebook: "Facebook",
          allRightsReserved: "All Rights Reserved",
          address: "〒150-0043 東京都渋谷区道玄坂東急ビル２FーC"
        }
      },
      en: {
        translation: {
          companyName: "Hacozy LLC",
          brandName: "Hacozy",
          home: "Home",
          about: "About",
          services: "Services",
          contact: "Contact",
          heroTitle: "Empowering Education Through Technology",
          heroSubtitle: "Join us in revolutionizing Japanese learning and habit formation.",
          getStarted: "Get Started",
          aboutUs: "About Us",
          aboutDescription: "Hacozy LLC is dedicated to providing high-quality, accessible online education for everyone.",
          ourServices: "Our Services",
          yuruiNihongoTitle: "Yurui Nihongo",
          yuruiNihongoDescription: "Japanese teaching service for those preparing for the JLPT exam. We support English and Burmese speakers.",
          learnMore: "Learn More",
          habitAppTitle: "Habit Formation App - Tomo",
          habitAppDescription: "Coming soon! An innovative app designed to help you build and maintain positive habits.",
          joinWaitlist: "Join Waitlist",
          testimonials: "What Our Students Say",
          testimonial1: "Yurui Nihongo has transformed my Japanese learning experience. The lessons are engaging, and the teachers are incredibly supportive!",
          testimonial1Author: "Sarah L., JLPT N3 Passer",
          testimonial2: "As a Burmese speaker, finding good Japanese learning resources was challenging until I found Yurui Nihongo. It's been a game-changer for me!",
          testimonial2Author: "Maung M., JLPT N4 Student",
          contactUs: "Contact Us",
          contactDescription: "Have questions or want to learn more? Reach out to us!",
          sendEmail: "Send Email",
          quickLinks: "Quick Links",
          followUs: "Follow Us",
          facebook: "Facebook",
          allRightsReserved: "All Rights Reserved",
          address: "2F-C Tokyu Building, Dogenzaka, Shibuya-ku, Tokyo 150-0043"
        }
      },
      my: {
        translation: {
          companyName: "Hacozy LLC",
          brandName: "Hacozy",
          home: "ပင်မစာမျက်နှာ",
          about: "ကျွန်ုပ်တို့အကြောင်း",
          services: "ဝန်ဆောင်မှုများ",
          contact: "ဆက်သွယ်ရန်",
          heroTitle: "နည်းပညာဖြင့် ပညာရေးကို မြှင့်တင်ခြင်း",
          heroSubtitle: "ဂျပန်စာသင်ယူခြင်းနှင့် အလေ့အကျင့်ကောင်းများ ပျိုးထောင်ခြင်းကို တော်လှန်ပြောင်းလဲရာတွင် ပါဝင်လိုက်ပါ။",
          getStarted: "စတင်ရန်",
          aboutUs: "ကျွန်ုပ်တို့အကြောင်း",
          aboutDescription: "Hacozy ကုမ္ပဏီသည် လူတိုင်းအတွက် အရည်အသွေးမြင့် အွန်လိုင်းပညာရေးကို လက်လှမ်းမီစေရန် ဆောင်ရွက်နေပါသည်။",
          ourServices: "ကျွန်ုပ်တို့၏ဝန်ဆောင်မှုများ",
          yuruiNihongoTitle: "ယူရွီနီဟွန်ဂို",
          yuruiNihongoDescription: "JLPT စာမေးပွဲပြင်ဆင်သူများအတွက် ဂျပန်စာသင်ကြားရေးဝန်ဆောင်မှု။ အင်္ဂလိပ်စကားပြောသူများနှင့် မြန်မာစကားပြောသူများကို ကျွန်ုပ်တို့ ပံ့ပိုးပေးပါသည်။",
          learnMore: "ပိုမိုလေ့လာရန်",
          habitAppTitle: "အလေ့အကျင့်ပျိုးထောင်ရေးအက်ပ် - Tomo",
          habitAppDescription: "မကြာမီလာမည်! သင့်ကို ကောင်းမွန်သော အလေ့အကျင့်များ တည်ဆောက်ရန်နှင့် ထိန်းသိမ်းရန် ကူညီပေးမည့် ဆန်းသစ်တီထွင်ထားသော အက်ပ်။",
          joinWaitlist: "စောင့်ဆိုင်းစာရင်းသွင်းရန်",
          testimonials: "ကျွန်ုပ်တို့၏ ကျောင်းသားများ ပြောကြားချက်",
          testimonial1: "ယူရွီနီဟွန်ဂိုက ကျွန်မရဲ့ ဂျပန်စာသင်ယူမှုအတွေ့အကြုံကို ပြောင်းလဲပစ်လိုက်ပါတယ်။ သင်ခန်းစာတွေက စိတ်ဝင်စားစရာကောင်းပြီး ဆရာ/မတွေရဲ့ ပံ့ပိုးမှုကလည်း အံ့ဩစရာပါပဲ!",
          testimonial1Author: "Sarah L.၊ JLPT N3 အောင်မြင်သူ",
          testimonial2: "မြန်မာစကားပြောသူတစ်ယောက်အနေနဲ့ ဂျပန်စာသင်ယူဖို့ ကောင်းမွန်တဲ့ သင်ထောက်ကူပစ္စည်းတွေ ရှာဖွေရတာ ခက်ခဲခဲ့ပါတယ်။ ယူရွီနီဟွန်ဂိုကို တွေ့တဲ့အထိပေါ့။ ကျွန်တော့်အတွက်တော့ တကယ့်ကို ပြောင်းလဲမှုတစ်ခုဖြစ်သွားပါတယ်။",
          testimonial2Author: "မောင် M.၊ JLPT N4 ကျောင်းသား",
          contactUs: "ဆက်သွယ်ရန်",
          contactDescription: "မေးမြန်းလိုသည်များ သို့မဟုတ် ပိုမိုသိရှိလိုပါက ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ။",
          sendEmail: "အီးမေးလ်ပို့ရန်",
          quickLinks: "အမြန်လင့်ခ်များ",
          followUs: "Follow လုပ်ရန်",
          facebook: "Facebook",
          allRightsReserved: "All Rights Reserved",
          address: "2F-C Tokyu Building, Dogenzaka, Shibuya-ku, Tokyo 150-0043"
        }
      }
    },
    lng: "ja", // Set default language to Japanese
    fallbackLng: "ja",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;