import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './LandingPage.css';
import globeIcon from './globe_icon.svg';
import heroImage from './hero_image.jpg'; // Make sure to add this image to your project

const LandingPage = () => {
  const { t, i18n } = useTranslation();
  const [languageOpen, setLanguageOpen] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguageOpen(false);
  };

  return (
    <div className="landing-page">
      <header className="header">
        <div className="container">
          <div className="logo">{t('brandName')}</div>
          <nav className="nav">
            <a href="#home" className="nav-item">{t('home', 'ホーム')}</a>
            <a href="#services" className="nav-item">{t('services', 'サービス')}</a>
            <a href="#about" className="nav-item">{t('about', '会社概要')}</a>
            <div className="language-selector">
              <button onClick={() => setLanguageOpen(!languageOpen)} className="language-button">
                <img src={globeIcon} alt="Global icon" className="globe-icon" />
              </button>
              {languageOpen && (
                <div className="language-dropdown">
                  <button onClick={() => changeLanguage('ja')}>日本語</button>
                  <button onClick={() => changeLanguage('en')}>English</button>
                  <button onClick={() => changeLanguage('my')}>မြန်မာ</button>
                </div>
              )}
            </div>
          </nav>
        </div>
      </header>

      <main>
        <section id="home" className="hero">
          <img src={heroImage} alt="Hero" className="hero-image" />
          <div className="container">
            <div className="hero-content">
              <h1>{t('heroTitle', 'Learn, explore, and grow with Hacozy')}</h1>
              <p>{t('heroSubtitle', 'Discover new cultures and languages through our innovative learning experiences')}</p>
              <a href="#services" className="cta-button">{t('getStarted')}</a>
            </div>
          </div>
        </section>
        <section id="about" className='about'>
          <div className='container'>
            <h1>{t("aboutTitle", "会社概要")}</h1>
            <p>{t('aboutContent', 'Hecozyはインターネット時代の教育をリードする会社です。')}</p>
          </div>
        </section>

        <section id="services" className="services">
          <div className="container">
            <h2>{t('ourServices', 'We Offer Best Services')}</h2>
            <div className="service-cards">
              <div className="service-card">
                <img src="/api/placeholder/50/50" alt="Yurui Nihongo" />
                <h3>{t('yuruiNihongoTitle', 'Yurui Nihongo')}</h3>
                <p>{t('yuruiNihongoDescription', 'Learn Japanese in a relaxed and fun environment')}</p>
              </div>
              <div className="service-card">
                <img src="/api/placeholder/50/50" alt="Habit App" />
                <h3>{t('habitAppTitle', 'Habit App')}</h3>
                <p>{t('habitAppDescription', 'Build lasting habits with our innovative app')}</p>
              </div>
              <div className="service-card">
                <img src="/api/placeholder/50/50" alt="Cultural Exchange" />
                <h3>{t('culturalExchangeTitle', 'Cultural Exchange')}</h3>
                <p>{t('culturalExchangeDescription', 'Immerse yourself in new cultures and perspectives')}</p>
              </div>
            </div>
          </div>
        </section>


        <section id="how-it-works" className="how-it-works">
          <div className="container">
            <h2>{t('howItWorks', 'How It Works')}</h2>
            <div className="steps">
              <div className="step">
                <div className="step-number">1</div>
                <h3>{t('step1Title', 'Choose Your Program')}</h3>
                <p>{t('step1Description', 'Select from our range of language and cultural programs')}</p>
              </div>
              <div className="step">
                <div className="step-number">2</div>
                <h3>{t('step2Title', 'Set Your Goals')}</h3>
                <p>{t('step2Description', 'Define your learning objectives and timeline')}</p>
              </div>
              <div className="step">
                <div className="step-number">3</div>
                <h3>{t('step3Title', 'Start Learning')}</h3>
                <p>{t('step3Description', 'Dive into interactive lessons and cultural experiences')}</p>
              </div>
            </div>
          </div>
        </section>

        <section id="testimonials" className="testimonials">
          <div className="container">
            <h2>{t('testimonials', 'What People Say About Us')}</h2>
            <div className="testimonial-cards">
              <div className="testimonial-card">
                <p>{t('testimonial1', 'Hacozy has transformed my language learning journey. The Yurui Nihongo approach made Japanese fun and accessible!')}</p>
                {t('testimonial1Author', 'Sarah T., Yurui Nihongo Student')}
              </div>
              <div className="testimonial-card">
                <p>{t('testimonial2', 'The Habit App has helped me stay consistent with my studies. It\'s like having a personal coach in my pocket!')}</p>
                {t('testimonial2Author', 'Michael L., Habit App User')}
              </div>
            </div>
          </div>
        </section>

        <section id="cta" className="cta">
          <div className="container">
            <h2>{t('ctaTitle', 'Ready to Start Your Journey?')}</h2>
            <p>{t('ctaDescription', 'Join Hacozy today and unlock a world of learning opportunities')}</p>
            <a href="#" className="cta-button">{t('getStarted', 'Get Started Now')}</a>
          </div>
        </section>
      </main>

      <footer className="footer">
        <div className="container">
          <div className="footer-content">
            <div className="company-info">
              <h3>{t('companyName', 'Hacozy LLC')}</h3>
              <p>{t('address', '123 Learning Street, Tokyo, Japan')}</p>
            </div>
            <div className="quick-links">
              <h3>{t('quickLinks', 'Quick Links')}</h3>
              <a href="#home">{t('home', 'Home')}</a>
              <a href="#services">{t('services', 'Services')}</a>
              <a href="#about">{t('about', 'About')}</a>
            </div>
            <div className="social-media">
              <h3>{t('followUs', 'Follow Us')}</h3>
              <a href="https://www.facebook.com/profile.php?id=100076740776491" target="_blank" rel="noopener noreferrer">{t('facebook', 'Facebook')}</a>
            </div>
          </div>
          <div className="copyright">
            <p>&copy; 2023 Hacozy LLC. {t('allRightsReserved', 'All Rights Reserved')}</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;